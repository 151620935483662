import { useActiveStoreView } from '@emico-hooks/use-active-storeview'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React from 'react'

import removeLeadingSlashFromUrl from '../lib/removeLeadingSlashFromUrl'
import { useStoreConfig } from '../lib/storeConfig'

/**
 * Component to add meta canonical data in <head>
 */

interface Props {
  /**
   * Should the URL param be included in the canonical URL
   */
  includeUrlParam?: boolean
}

const MetaCanonical = ({ includeUrlParam = false }: Props) => {
  const { asPath } = useRouter()
  const { storeConfig } = useStoreConfig()
  const activeStoreView = useActiveStoreView()
  const baseUrl = storeConfig?.baseUrl
  const cleanUrl = includeUrlParam
    ? removeLeadingSlashFromUrl(asPath)
    : removeLeadingSlashFromUrl(asPath.split('?')[0])

  const absoluteUrl = new URL(
    cleanUrl,
    baseUrl ?? activeStoreView.basename,
  ).toString()

  const canonicalUrl = absoluteUrl.endsWith('/')
    ? absoluteUrl.slice(0, -1)
    : absoluteUrl

  return (
    <Head>
      <link rel="canonical" href={canonicalUrl} />
      <meta property="og:url" content={canonicalUrl} />
    </Head>
  )
}

export default MetaCanonical
