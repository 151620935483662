import styled from '@emotion/styled'
import React, { ComponentProps } from 'react'

import theme from '../theme'
import NextLink from './NextLink'

const StyledNextLink = styled(NextLink, {
  shouldForwardProp: (prop) => !['isBasic'].includes(prop.toString()),
})<{ isBasic?: boolean }>`
  color: ${({ isBasic }) =>
    isBasic
      ? 'var(--next-link-basic-color, currentColor)'
      : `var(--next-link-color, ${theme.colors.primary})`};
  text-decoration: ${({ isBasic }) => (isBasic ? 'underline' : 'none')};
  ${({ isBasic }) => !isBasic && theme.animation.linkHover()};

  &:hover {
    color: ${({ isBasic }) =>
      isBasic
        ? 'var(--next-link-basic-color, currentColor)'
        : `var(--next-link-color, ${theme.colors.primary})`};
    text-decoration: ${({ isBasic }) => (isBasic ? 'underline' : 'none')};
  }
`

interface Props extends ComponentProps<typeof NextLink> {
  isBasic?: boolean
}

const NextLinkPrimary = (props: Props) => <StyledNextLink {...props} />

export default NextLinkPrimary
