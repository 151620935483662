import { ProductFragment } from '@emico-hooks/product-fragment'

import { Maybe } from '@emico/graphql-schema-types'

/**
 * Returns boolean if product is preorder, based on product.preorder data
 */

export default (
  preorder?: Maybe<Exclude<ProductFragment['preorder'], null>>,
) => {
  const today = new Date()
  const atpDate = preorder?.atpDate && new Date(preorder.atpDate)
  // Product is preorder when isPreorder and atpDate is in the future
  const isPreorder = preorder?.isPreorder && atpDate && atpDate > today

  return Boolean(isPreorder)
}
