import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { minWidth } from '@emico/styles'

import theme from '../theme'
import ButtonPrimary from './ButtonPrimary'

type ColorTheme = 'light' | 'dark'

const getColor = (colorTheme?: ColorTheme, reversed?: boolean) =>
  reversed
    ? colorTheme === 'dark'
      ? theme.colors.white
      : theme.colors.text
    : colorTheme === 'dark'
    ? theme.colors.text
    : theme.colors.white

const ButtonSecondary = styled(ButtonPrimary, {
  shouldForwardProp: (prop) =>
    !['colorTheme', 'isActive'].includes(prop.toString()),
})<{
  colorTheme?: ColorTheme
  isActive?: boolean
}>`
  background: none;
  color: var(
    --button-secondary-color,
    ${({ colorTheme }) => getColor(colorTheme)}
  );
  border-color: var(
    --button-secondary-border,
    ${({ colorTheme }) => getColor(colorTheme)}
  );

  &::before {
    background: var(
      --button-secondary-hover-background,
      ${({ colorTheme }) => getColor(colorTheme)}
    );
  }

  &:hover {
    background: none;
    color: ${({ colorTheme }) =>
      colorTheme === 'dark' &&
      `var(
      --button-secondary-hover-on-background,
      var(--button-secondary-on-background, ${theme.colors.text})
    )`};
  }

  &:disabled {
    background: none;
  }

  @media ${minWidth('lg')} {
    &:hover {
      color: var(
        --button-secondary-hover-on-background,
        var(
          --button-secondary-on-background,
          ${({ colorTheme }) => getColor(colorTheme, true)}
        )
      );
      border-color: var(
        --button-secondary-hover-border,
        ${({ colorTheme }) => getColor(colorTheme)}
      );
    }

    ${({ isActive, colorTheme }) =>
      isActive &&
      css`
        color: var(
          --button-secondary-hover-on-background,
          var(--button-secondary-on-background, ${getColor(colorTheme, true)})
        );
        border-color: var(
          --button-secondary-hover-border,
          ${getColor(colorTheme)}
        );

        &::before {
          transform: scaleX(1);
        }
      `}
  }
`

export default ButtonSecondary
