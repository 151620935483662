import { gql, TypedDocumentNode } from '@apollo/client'

import productCardFragment from '@emico-hooks/product-card-fragment'
import { productParentFragment } from '@emico-hooks/product-fragment'

import { CartItemFragment } from './CartItemFragment.generated'

export const cartItemFragment = gql`
  fragment CartItemFragment on CartItemInterface {
    id
    uid
    quantity

    product {
      ...ProductCardFragment

      ... on SimpleProduct {
        parentData {
          ...ProductParentFragment
        }
      }
    }

    preorder {
      atpDate
      atpDateFormatted
      isPreorder
    }

    prices {
      price {
        currency
        value
      }

      rowTotal {
        currency
        value
      }

      rowTotalIncludingTax {
        currency
        value
      }

      totalItemDiscount {
        currency
        value
      }

      discounts {
        label
        amount {
          currency
          value
        }
      }
    }

    ... on ConfigurableCartItem {
      configurableOptions {
        ... on SelectedConfigurableOption {
          configurableProductOptionUid
          configurableProductOptionValueUid
          optionLabel
          valueLabel
        }
      }

      configuredVariant {
        ... on ProductInterface {
          name

          preorder {
            atpDate
            atpDateFormatted
            isPreorder
          }

          smallImage {
            url
            label
          }
        }
      }
    }
  }

  ${productCardFragment}
  ${productParentFragment}
` as TypedDocumentNode<CartItemFragment, never>
