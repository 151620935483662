import styled from '@emotion/styled'
import React from 'react'

import theme from '../theme'

const Error = styled.p`
  margin: ${theme.spacing.xs} 0 0;
  color: ${theme.colors.error};
  font-size: ${theme.fontSizes.sm};
`

interface Props {
  message: string
}

const InputError = ({ message, ...other }: Props) => (
  <Error {...other}>{message}</Error>
)

export default InputError
