import { pushLinkClick, testId } from '@emico-utils/datalayer'
import Link from 'next/link'
import React, { ComponentProps, ReactNode } from 'react'

const isAnalyticsEnabled =
  process.env.REACT_APP_DISABLE_ANALYTICS_LINK_CLICK !== 'true'

interface Props extends ComponentProps<typeof Link> {
  children: ReactNode
  className?: string
  ariaLabel?: string
  rel?: string
  target?: string

  // GTM
  /** Tracking event context. Use a visual breadcrumb id, e.g. header.subMenu.category */
  analyticsContext?: string
  /** Tracking event name */
  analyticsName?: string
}

const NextLink = ({
  children,
  className,
  ariaLabel,
  rel,
  target,
  onClick,
  analyticsContext,
  analyticsName,
  ...other
}: Props) => (
  <Link
    className={className}
    aria-label={ariaLabel}
    target={target}
    rel={target === '_blank' ? `noopener noreferrer` : undefined}
    data-testid={testId(analyticsName, analyticsContext)}
    onClick={
      isAnalyticsEnabled
        ? (e) => {
            onClick?.(e)

            pushLinkClick(analyticsName, analyticsContext)
          }
        : undefined
    }
    {...other}
  >
    {children}
  </Link>
)

export default NextLink
