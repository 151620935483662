import styled from '@emotion/styled'
import { HTMLAttributes, useEffect, useRef, useState } from 'react'
import { Collapse } from 'react-collapse'

import { CheckIcon, CrossRoundIcon, InfoCircleIcon } from '@emico/icons'
import { AlertType, Container } from '@emico/ui'

import theme from '../theme'

const AlertWrapper = styled.div`
  .ReactCollapse--collapse {
    transition-property: ${theme.transition.properties.dimensions};
    transition-duration: ${theme.transition.durations.normal};
    transition-timing-function: ${theme.transition.timingFunctions
      .cubicBezierSmooth};
  }
`

const backgroundColor: Record<string, string> = {
  info: theme.colors.info,
  success: theme.colors.success,
  warning: theme.colors.warning,
  error: theme.colors.error,
}

const textColor: Record<string, string> = {
  info: theme.colors.white,
  success: theme.colors.white,
  warning: theme.colors.black,
  error: theme.colors.white,
}

interface Props extends HTMLAttributes<HTMLDivElement> {
  type?: AlertType
  showIcon?: boolean
  hasAutoHide?: boolean
}

const AlertIcons = {
  info: <InfoCircleIcon />,
  success: <CheckIcon />,
  warning: <CheckIcon />,
  error: <CrossRoundIcon />,
}

const Bar = styled('div', {
  shouldForwardProp: (prop) => !['type'].includes(prop.toString()),
})<{ type: AlertType }>`
  padding: ${theme.spacing.sm} 0;
  background-color: ${({ type }) => backgroundColor[type]};
  color: ${({ type }) => textColor[type]};
  font-size: ${theme.fontSizes.md};
  line-height: ${theme.lineHeights.md};
`

const StyledContainer = styled(Container, {
  shouldForwardProp: (prop) => !['showIcon'].includes(prop.toString()),
})<{ showIcon: boolean }>`
  ${({ showIcon }) =>
    showIcon &&
    `
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 0.5rem;`}}
`

const autoHideMillis = 1500

const Alert = ({
  children,
  showIcon = false,
  hasAutoHide = false,
  type = 'info',
  ...other
}: Props) => {
  const [hidden, setHidden] = useState<boolean>(true)
  const timerRef = useRef<number>()

  useEffect(() => {
    if (hasAutoHide && children) {
      timerRef.current = window.setTimeout(() => {
        setHidden(true)
      }, autoHideMillis)
    }

    return () => {
      clearTimeout(timerRef.current)
      setHidden(false)
    }
  }, [hasAutoHide, children])

  return (
    <AlertWrapper onClick={() => setHidden(true)}>
      <Collapse isOpened={!hidden}>
        {children && (
          <Bar role="alert" type={type} {...other}>
            <StyledContainer showIcon={showIcon}>
              {showIcon && AlertIcons[type]} {children}
            </StyledContainer>
          </Bar>
        )}
      </Collapse>
    </AlertWrapper>
  )
}

export default Alert
