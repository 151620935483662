import React from 'react'

import styled from '@emotion/styled'

import { minWidth } from '@emico/styles'
import { GlobalSetType } from './lib/useCraftGlobalSets'
import theme from './theme'
import CraftLink from './components/CraftLink'

const MainLinksList = styled.ul`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  justify-content: center;
`

const MainLinksListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-around;

  &:not(:last-child) {
    &::after {
      content: '-';
      display: inline-block;
      margin: 0 ${theme.spacing.xs};
      color: ${theme.colors.textLight};
    }
  }
`

const Copyright = styled.p`
  margin: 0;
  color: ${theme.colors.textLight};
  font-size: ${theme.fontSizes.xs};
`

const StyledCrafLink = styled(CraftLink)`
  font-size: ${theme.fontSizes.xs};
  color: ${theme.colors.textLight};
  text-decoration: none;

  &:hover {
    color: ${theme.colors.textLight};
    text-decoration: none;
  }

  @media ${minWidth('lg')} {
    ${theme.animation.linkHover()};
  }
`

interface Props {
  footerGlobalSet: GlobalSetType
}

const FooterMainLinks = ({ footerGlobalSet, ...other }: Props) => {
  if (
    footerGlobalSet?.__typename !== 'CraftFooterGlobalSet' ||
    (!footerGlobalSet.copyright && footerGlobalSet.footerLinks?.length === 0)
  ) {
    return null
  }

  return (
    <MainLinksList {...other}>
      {footerGlobalSet.copyright && (
        <MainLinksListItem>
          <Copyright>{footerGlobalSet.copyright}</Copyright>
        </MainLinksListItem>
      )}

      {footerGlobalSet.footerLinks?.length !== 0 &&
        footerGlobalSet.footerLinks?.map((link) => {
          if (!link?.linkItem?.url || !link.linkItem.customText) {
            return null
          }

          return (
            <MainLinksListItem key={link.linkItem.customText}>
              <StyledCrafLink
                analyticsContext="footer"
                analyticsName={link.linkItem.url}
                craftLink={link.linkItem}
              >
                {link?.linkItem?.customText}
              </StyledCrafLink>
            </MainLinksListItem>
          )
        })}
    </MainLinksList>
  )
}

export default FooterMainLinks
