import { gql, TypedDocumentNode } from '@apollo/client'

import { ProductParentFragment } from './ProductParentFragment.generated'
import { productConfigurableOptionsFragment } from './ProductConfigurableOptionsFragment'

export const productParentFragment = gql`
  fragment ProductParentFragment on ParentData {
    mainSlug

    preselectOptions {
      uid
      attributeCode
      label
      value
    }

    product {
      uid
      name

      ... on ConfigurableProduct {
        variants {
          attributes {
            code
            label
            valueIndex
            uid
          }

          product {
            uid
            urlKey
            onlyXLeftInStock
            stockStatus

            preorder {
              atpDate
              atpDateFormatted
              isPreorder
            }

            image {
              url
            }
          }
        }

        configurableOptions {
          ...ProductConfigurableOptionsFragment
        }
      }
    }
  }

  ${productConfigurableOptionsFragment}
` as TypedDocumentNode<ProductParentFragment, never>
