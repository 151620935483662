import { Image } from '@emico-react/image'
import React, { ComponentProps } from 'react'

import { Maybe } from '@emico/graphql-schema-types'

import { CraftImageFragment } from '../lib/craftFragments.generated'

interface Props
  extends Omit<ComponentProps<typeof Image>, 'url' | 'alt' | 'lazy'> {
  image?: Maybe<
    CraftImageFragment & {
      desktopUrl?: Maybe<string>
      tabletUrl?: Maybe<string>
      mobileUrl?: Maybe<string>
      customUrl?: Maybe<string>
    }
  >
  type?: 'default' | 'desktop' | 'tablet' | 'mobile' | 'custom'
  lazy?: boolean
}

const CraftImage = ({
  image,
  type = 'default',
  sizes,
  lazy = false,
  className,
  ...other
}: Props) => {
  if (!image) {
    return null
  }

  const url = (() => {
    switch (type) {
      case 'desktop':
        return 'desktopUrl' in image ? image.desktopUrl : image.url
      case 'tablet':
        return 'tabletUrl' in image ? image.tabletUrl : image.url
      case 'mobile':
        return 'mobileUrl' in image ? image.mobileUrl : image.url
      case 'custom':
        return 'customUrl' in image ? image.customUrl : image.url
      default:
        return image.defaultUrl ?? image.url
    }
  })()

  if (!url) {
    return null
  }

  if (url.endsWith('.svg')) {
    if (!image.url || !image.height || !image.width) {
      return null
    }

    return (
      <Image
        url={image.url}
        alt={image.title ?? ''}
        className={className}
        height={image.height}
        lazy={false}
        width={image.width}
        {...other}
      />
    )
  }

  return (
    <Image
      url={url}
      originalWidth={image.width ?? undefined}
      originalHeight={image.height ?? undefined}
      alt={image.title ?? ''}
      sizes={sizes}
      lazy={lazy}
      className={className}
      {...other}
    />
  )
}

export default CraftImage
