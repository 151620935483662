import { useRouter } from 'next/router'
import React, { ComponentProps } from 'react'

import { CraftLinkFragment } from '../lib/craftFragments.generated'
import NextLink from './NextLink'

export const getUrl = (craftLink: CraftLinkFragment, basePath: string) => {
  if (!craftLink.url) {
    return null
  }

  switch (craftLink.type) {
    // return anchor link
    case 'anchorLink': {
      return `#${craftLink.url}`
    }
    // return directly callable link (including 'tel:')
    case 'tel': {
      return craftLink.url
    }
  }

  // return relative URL
  if (craftLink.url.indexOf('http') === -1) {
    return craftLink.url.charAt(0) === '/' ? craftLink.url : `/${craftLink.url}`
  }

  const url = new URL(craftLink.url)

  if (basePath !== url.hostname) {
    return craftLink.url
  }

  let path =
    url.pathname.charAt(0) === '/'
      ? url.pathname.substring(1).length > 0
        ? url.pathname.substring(1)
        : '/'
      : url.pathname

  path = url.search ? `${path}${url.search}` : path

  return path
}

interface Props extends Omit<ComponentProps<typeof NextLink>, 'href'> {
  craftLink: CraftLinkFragment
}

const CraftLink = ({ children, craftLink, ...other }: Props) => {
  const { basePath } = useRouter()
  const url = getUrl(craftLink, basePath)

  if (!url) {
    return null
  }

  return (
    <NextLink href={url} target={craftLink.target ?? undefined} {...other}>
      {children}
    </NextLink>
  )
}

export default CraftLink
