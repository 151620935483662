import { gql, TypedDocumentNode } from '@apollo/client'

import { Unmaybe } from '@emico/utils'

import cartAddressFragment from './CartAddressFragment'
import { CartFragment } from './CartFragment.generated'
import { cartItemFragment } from './CartItemFragment'

export const cartFragment = gql`
  fragment CartFragment on Cart {
    id
    email
    totalQuantity
    lastItemUid
    items {
      ...CartItemFragment
    }

    itemErrors {
      id
      code
      message
    }

    prices {
      appliedTaxes {
        amount {
          currency
          value
        }
        label
      }

      grandTotal {
        currency
        value
      }

      subtotalIncludingTax {
        currency
        value
      }

      subtotalExcludingTax {
        currency
        value
      }

      subtotalWithDiscountExcludingTax {
        currency
        value
      }

      discounts {
        amount {
          currency
          value
        }
        label
      }
    }

    availablePaymentMethods {
      code
      title
      image
    }

    selectedPaymentMethod {
      code
      title
    }

    appliedCoupons {
      code
    }

    billingAddress {
      ...CartAddressFragment
    }

    shippingAddresses {
      ...CartAddressFragment

      selectedShippingMethod {
        amount {
          value
          currency
        }

        carrierTitle
        carrierCode
        methodTitle
        methodCode
      }

      availableShippingMethods {
        available
        carrierCode
        carrierTitle
        methodCode
        methodTitle
        priceInclTax {
          value
          currency
        }
      }
    }
  }
  ${cartItemFragment}
  ${cartAddressFragment}
` as TypedDocumentNode<CartFragment, never>

export type ShippingAddress = Exclude<
  CartFragment['shippingAddresses'][0],
  null
>

export type AvailableShippingMethod = Unmaybe<
  CartFragment,
  'shippingAddresses',
  'availableShippingMethods'
>
