import styled from '@emotion/styled'
import { Maybe } from 'graphql/jsutils/Maybe'

import { Money } from '@emico/graphql-schema-types'
import { CurrencyDisplay } from '@emico/price'

import theme from '../theme'
import Price from './Price'

const PriceWrapper = styled.div`
  display: flex;
  align-items: center;
`

const StyledPriceRegular = styled(Price)`
  position: relative;
  margin-right: ${theme.spacing.sm};
  color: ${theme.colors.grayBrown};
  font-weight: ${theme.fontWeights.normal};

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform-origin: center;
    transform: translateY(-1px) rotate(-12deg);
    width: 100%;
    height: 2px;
    background: ${theme.colors.grayBrown};
  }
`

const StyledPriceFinal = styled(Price)`
  color: ${theme.colors.primary};
`

interface Props {
  percentOff?: Maybe<number>
  regularPrice: Maybe<Money>
  finalPrice: Maybe<Money>
  currencyDisplay?: CurrencyDisplay
  isLarge?: boolean
}

const RegularFinalPrice = ({
  regularPrice,
  finalPrice,
  percentOff,
  currencyDisplay = 'none',
  isLarge = false,
  ...other
}: Props) =>
  percentOff ? (
    <PriceWrapper {...other}>
      <StyledPriceRegular
        price={regularPrice}
        currencyDisplay={currencyDisplay}
        isLarge={isLarge}
      />

      <StyledPriceFinal
        price={finalPrice}
        currencyDisplay={currencyDisplay}
        isLarge={isLarge}
      />
    </PriceWrapper>
  ) : (
    <Price
      price={finalPrice}
      currencyDisplay={currencyDisplay}
      isLarge={isLarge}
      {...other}
    />
  )

export default RegularFinalPrice
