import { gql, TypedDocumentNode } from '@apollo/client'

import { ProductConfigurableOptionsFragment } from './ProductConfigurableOptionsFragment.generated'

export const productConfigurableOptionsFragment = gql`
  fragment ProductConfigurableOptionsFragment on ConfigurableProductOptions {
    uid
    attributeCode
    attributeUid
    label
    position

    values {
      label
      defaultLabel
      storeLabel
      uid
      useDefaultValue

      swatchData {
        value
        ... on ImageSwatchData {
          thumbnail
        }
      }
    }
  }
` as TypedDocumentNode<ProductConfigurableOptionsFragment, never>
