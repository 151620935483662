import styled from '@emotion/styled'
import React from 'react'

import theme from '../theme'

export const Label = styled.label`
  margin: 0;
  padding: ${theme.spacing.xs};
  position: absolute;
  top: 0;
  left: ${theme.spacing.sm};
  pointer-events: none;
  background-color: ${theme.colors.background};
  color: ${theme.colors.grayBrown};
  transition-property: ${theme.transition.properties.common};
  transition-duration: ${theme.transition.durations.normal};
  transition-timing-function: ${theme.transition.timingFunctions
    .cubicBezierSmooth};
  transform-origin: top left;
  transform: translateY(calc((${theme.sizes.inputHeight} / 2) - 50%));
`

interface Props {
  children: string
}

const InputLabel = ({ children, ...other }: Props) => (
  <Label {...other}>{children}</Label>
)

export default InputLabel
