import { gql, TypedDocumentNode } from '@apollo/client'

import { CartAddressFragment } from './CartAddressFragment.generated'

const cartAddressFragment = gql`
  fragment CartAddressFragment on CartAddressInterface {
    firstname
    lastname
    company
    street
    postcode
    city
    country {
      code
      label
    }
    telephone
  }
` as TypedDocumentNode<CartAddressFragment, never>

export default cartAddressFragment
