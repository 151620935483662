import { gql, TypedDocumentNode } from '@apollo/client'

import { productLabelFragment } from './ProductLabelFragment'

import { ProductLabelListFragment } from './ProductLabelListFragment.generated'

export const productLabelListFragment = gql`
  fragment ProductLabelListFragment on AmLabelList {
    items {
      ...ProductLabelFragment
    }
  }

  ${productLabelFragment}
` as TypedDocumentNode<ProductLabelListFragment, never>
