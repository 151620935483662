import { gql, TypedDocumentNode } from '@apollo/client'

import { ProductLabelFragment } from './ProductLabelFragment.generated'

export const productLabelFragment = gql`
  fragment ProductLabelFragment on AmLabel {
    labelId
    name
    txt
    position
    style
    isVisible
  }
` as TypedDocumentNode<ProductLabelFragment, never>
