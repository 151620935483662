import { CurrencyEnum } from '@emico/graphql-schema-types'

import { CartFragment } from './CartFragment.generated'

const OPTIMISTIC_CART_ID = 'TEMP_OPTIMISTIC_CART'

/**
 * An empty cart. This is defined in the cart-fragment package because this is
 * very tightly coupled. Changes to the fragment require changes in this object.
 */
const emptyCart: CartFragment = {
  id: OPTIMISTIC_CART_ID,
  items: [],
  itemErrors: [],
  prices: {
    grandTotal: {
      value: 0,
      currency: CurrencyEnum.EUR,
      __typename: 'Money',
    },
    subtotalIncludingTax: {
      value: 0,
      currency: CurrencyEnum.EUR,
      __typename: 'Money',
    },
    subtotalExcludingTax: {
      value: 0,
      currency: CurrencyEnum.EUR,
      __typename: 'Money',
    },
    subtotalWithDiscountExcludingTax: {
      value: 0,
      currency: CurrencyEnum.EUR,
      __typename: 'Money',
    },
    discounts: [],
    appliedTaxes: [],
    __typename: 'CartPrices',
  },
  availablePaymentMethods: [],
  email: null,
  totalQuantity: 0,
  lastItemUid: null,
  billingAddress: null,
  appliedCoupons: null,
  shippingAddresses: [],
  selectedPaymentMethod: {
    code: '',
    title: '',
  },
  __typename: 'Cart',
}

export default emptyCart
